@import 'mixins/buttons';
@import 'mixins/dot-flashing';

.button {
    @include themable-button;
}

:global(.gtamerica) {
    .button {
        font-family: $ff-gt-america-mono-medium;
    }
}

@include dot-flashing;
