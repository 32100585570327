/**
 * ==============================================
 * Dot Flashing: https://codepen.io/nzbin/pen/GGrXbp
 * ==============================================
 */
@mixin dot-flashing {
    .dot-flashing {
        animation: dotFlashing 1s infinite linear alternate;
        animation-delay: 0.5s;
        background-color: currentColor;
        border-radius: $spacing--1;
        color: currentColor;
        height: $spacing-00;
        margin: $spacing--1 0;
        position: relative;
        width: $spacing-00;
    }

    .dot-flashing::before,
    .dot-flashing::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
    }

    .dot-flashing::before {
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 0s;
        background-color: currentColor;
        border-radius: $spacing-1;
        color: currentColor;
        height: $spacing-00;
        left: -$spacing-1;
        width: $spacing-00;
    }

    .dot-flashing::after {
        animation: dotFlashing 1s infinite alternate;
        animation-delay: 1s;
        background-color: currentColor;
        border-radius: $spacing--1;
        color: currentColor;
        height: $spacing-00;
        left: $spacing-1;
        width: $spacing-00;
    }

    @keyframes dotFlashing {
        0% {
            background-color: currentColor;
        }
        50%,
        100% {
            background-color: $cxo-neutral-color-1;
        }
    }
}
